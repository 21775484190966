import React, { Component } from 'react';

const css = `
.usertable {
    max-width:100%;
}
.menu-hide-logo-position{
    left: 10px;
width: calc(100% - 10px);
transition: all 0.3s ease;
}
`
class Error404 extends Component {
  render() {
    return (
      <>
        <style>{css}</style>
        <div className='d-flex align-items-center justify-content-center h-100'>
          <img src="/images/404-page.png" alt="404 page" />
        </div>
      </>
    )
  }
}


export default Error404;