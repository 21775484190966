import axios from "axios";
import { alertService } from "../components/GlobalComponent/alertService";
import { API_URL, handleError, FETCH_ASK_RESULTS, SET_QUESTION, ALLOWED_WHITELISTED_DOMAINS } from "../actions/index";

export const setCustomerQuery =
  (question) =>
    async (dispatch) => {      
      dispatch({
        type: SET_QUESTION,
        payload: question,
      });
    };
export const customerQuery =
  (question, token,workStartDate) =>
    async (dispatch) => {
      try {
        let ask_question_url = `${API_URL}/customerusage/askdata`;
        const parsedUrl = new URL(ask_question_url);
        if (ALLOWED_WHITELISTED_DOMAINS.includes(`${parsedUrl.protocol}//${parsedUrl.host}`)) {
          let payload = {
            "question_text": question,
            "work_star_date":workStartDate
          }
          let res;
          res = await axios.post(ask_question_url, payload, {
            headers: {
              token: token
            }
          });       
          let results = res.data;
          dispatch({
            type: FETCH_ASK_RESULTS,
            payload: results,
          });
        } else {
          alertService.error('Domain not allowed', { autoClose: true });
          const error = new Error('Domain not allowed');
          handleError(error, dispatch);
        }
      } catch (error) {
        handleError(error, dispatch);
      }
    };
