
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Row from "react-bootstrap/Row";
import PropTypes from 'prop-types';
import Col from "react-bootstrap/Col";
import BarChartComponent from "./BarChartComponent";
import AreaChartComponent from "./AreaChartComponent";

import { getResourceUtilizationReportTeamWise, getResourceUtilizationMonthlyReport } from "../../actions/index";

class ResourceChart extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      teamChartInfo: [],
      monthChartInfo: []
    };
  }

  async componentDidMount() {
    await this.props.getResourceUtilizationReportTeamWise(this.props.dateInfo.startDate, this.props.dateInfo.endDate, this.props.team);
    await this.props.getResourceUtilizationMonthlyReport(this.props.team);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.dateInfo.startDate !== prevProps.dateInfo.startDate || this.props.dateInfo.endDate !== prevProps.dateInfo.endDate || this.props.team !== prevProps.team) {
      await this.props.getResourceUtilizationReportTeamWise(this.props.dateInfo.startDate, this.props.dateInfo.endDate, this.props.team);
    }

    if (this.props.team !== prevProps.team) {
      await this.props.getResourceUtilizationMonthlyReport(this.props.team);
    }

    if (this.props.teamResourceReport && this.props.teamResourceReport !== prevProps.teamResourceReport) {
      this.setState({ teamChartInfo: this.props.teamResourceReport.data });
    }
    if (this.props.monthlyResourceReport && this.props.monthlyResourceReport !== prevProps.monthlyResourceReport) {
      this.setState({ monthChartInfo: this.props.monthlyResourceReport.data });
    }

  }

  render() {
    return (
      <Row>
        <Col md={6} sm={12} xs={12}>
          {/* <p className="mb-0 border-bottom pb-1">Team Utilization Report</p> */}
          {this.state.teamChartInfo && this.state.teamChartInfo.length > 0 ? (<BarChartComponent info={this.state.teamChartInfo} team={this.props.team}/>) : ''}
        </Col>
        <Col md={6} sm={12} xs={12}>
          {/* <p className="mb-0 border-bottom pb-1">Last Month VS This Month</p> */}
          {this.state.monthChartInfo && this.state.monthChartInfo.length > 0 ? (<AreaChartComponent info={this.state.monthChartInfo} />) : ''}
        </Col>
      </Row>
    );
  }
}

ResourceChart.propTypes = {
  dateInfo: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date)
  }).isRequired, // Validate dateInfo prop
  team: PropTypes.string, // Validate dateInfo prop
  getResourceUtilizationReportTeamWise: PropTypes.func.isRequired,
  getResourceUtilizationMonthlyReport: PropTypes.func.isRequired,
  teamResourceReport: PropTypes.object,
  monthlyResourceReport: PropTypes.object
};

function mapStateToProps(state) {
  return {
    teamResourceReport: state.resourceUtilization.teamResourceReport,
    monthlyResourceReport: state.resourceUtilization.monthlyResourceReport,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      getResourceUtilizationReportTeamWise,
      getResourceUtilizationMonthlyReport
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ResourceChart);
