import React, { Component } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';

class ExportExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  sanitizeTeamString = (team) => {
    return team
      .toLowerCase()               // Convert to lowercase
      .replace(/\s+/g, '_')       // Replace spaces with underscores
      .replace(/[^\w_]/g, '');    // Remove any non-word characters except underscores
  };

  handleExport = async () => {
    this.setState({ loading: true });

    try {
      const data = await this.props.getDataFunction(this.props.dateInfo.startDate, this.props.dateInfo.endDate, this.props.team);

      // Create a new workbook and a worksheet
      const wb = XLSX.utils.book_new();

      // Construct the filename based on dateInfo and team
      const startDateStr = moment(this.props.dateInfo.startDate).format('M/D/YYYY');
      const endDateStr = moment(this.props.dateInfo.endDate).format('M/D/YYYY');

      const exportData = [
        [`From ${startDateStr} to ${endDateStr} for ${this.props.team} Team`],
        [],
        ['Team', 'Employee Name', 'Employee Email', 'Working Hours', 'Utilization Hours', 'Utilization Percentage']
      ];

      let previousTeam = '';
      if(data?.data.length > 0) {
        for(const utilizationData of data.data) {
          const currentTeam = utilizationData.team;
          const rowData = [
            currentTeam !== previousTeam ? currentTeam : '',
            utilizationData.emp_name,
            utilizationData.email,
            utilizationData.working_hours,
            utilizationData.utilization_hours,
            `${utilizationData.utilization_percentage}%`
          ];
          exportData.push(rowData);
          previousTeam = currentTeam;  // Update previousTeam to the current team
        }
      } else {
        exportData.push(['No Data found']);
      }

      const ws = XLSX.utils.aoa_to_sheet(exportData);

      // Merge cells for the title row
      ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }  // Merge cells from row 0, col 0 to row 0, col 5
      ];

      if(!data?.data || data?.data.length <= 0) {
        ws['!merges'].push({ s: { r: 3, c: 0 }, e: { r: 3, c: 5 } })
      }

      // Calculate column widths
      const maxLengths = exportData.reduce((maxLengths, row) => {
        row.forEach((cell, i) => {
          const length = cell ? cell.toString().length : 0;
          if (maxLengths[i]) {
            if (length > maxLengths[i]) {
              maxLengths[i] = length;
            }
          } else {
            maxLengths[i] = length;
          }
        });
        return maxLengths;
      }, []);

      // Set column widths
      const wsCols = maxLengths.map(len => ({ wch: len + 2 })); // Adding some padding
      ws['!cols'] = wsCols;

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Utilization Report');

      const teamStr = this.sanitizeTeamString(this.props.team || 'all');
      const fileName = `utilization_report_${startDateStr}_${endDateStr}_${teamStr}.xlsx`;

      // Generate a buffer and save the workbook
      XLSX.writeFile(wb, fileName);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;

    return (
        <div className='me-auto mt-0 mt-md-2 mb-3 mb-lg-0' disabled={loading}>
          <Button className="w-auto border px-3 py-2 text-nowrap rounded" onClick={this.handleExport} startIcon={<FileDownloadIcon />}><span className='text-dark'>{loading ? 'Exporting...' : "Export"}</span></Button>
        </div>
    );
  }
}

ExportExcel.propTypes = {
    dateInfo: PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date)
    }).isRequired, // Validate dateInfo prop
    team: PropTypes.string, // Validate team prop
    getDataFunction: PropTypes.func.isRequired
  };

export default ExportExcel;