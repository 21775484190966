
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Row from "react-bootstrap/Row";
import PropTypes from 'prop-types';
import Col from "react-bootstrap/Col";
import BarChartComponent from "./BarChartComponent";
import DoubleBarChartComponent from "./DoubleBarChartComponent";

import { getUtilizationTeamReport, getUtilizationMonthlyReport } from "../../actions/index";

class ResourceChart extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      teamChartInfo: [],
      monthChartInfo: [],
      userChartInfo: [],
      monthUserChartInfo: []
    };
  }

  async componentDidMount() {
    try {
      await this.props.getUtilizationTeamReport(this.props.dateInfo.startDate, this.props.dateInfo.endDate);
      await this.props.getUtilizationMonthlyReport();
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.dateInfo.startDate !== prevProps.dateInfo.startDate || this.props.dateInfo.endDate !== prevProps.dateInfo.endDate) {
      await this.props.getUtilizationTeamReport(this.props.dateInfo.startDate, this.props.dateInfo.endDate);
    }

    if (this.props.teamReport && this.props.teamReport !== prevProps.teamReport) {
      this.setState({ teamChartInfo: this.props.teamReport });
    }
    if (this.props.monthlyReport && this.props.monthlyReport !== prevProps.monthlyReport) {
      this.setState({ monthChartInfo: this.props.monthlyReport });
    }

    if (this.props.userReport && this.props.userReport !== prevProps.userReport) {
      this.setState({ userChartInfo: this.props.userReport });
    }
    if (this.props.monthlyUserReport && this.props.monthlyUserReport !== prevProps.monthlyUserReport) {
      this.setState({ monthUserChartInfo: this.props.monthlyUserReport });
    }

  }

  render() {
    return (
      <>
        <Row>
          <Col md={6} sm={12} xs={12}>
            {/* <p className="mb-0 border-bottom pb-1">Team Utilization Report</p> */}
            {this.state.teamChartInfo && this.state.teamChartInfo.length > 0 ? (<BarChartComponent type="Customer" info={this.state.teamChartInfo} />) : ''}
          </Col>
          <Col md={6} sm={12} xs={12}>
            {/* <p className="mb-0 border-bottom pb-1">Last Month VS This Month</p> */}
            {this.state.monthChartInfo && this.state.monthChartInfo.length > 0 ? (<DoubleBarChartComponent info={this.state.monthChartInfo} />) : ''}
          </Col>
        </Row>

        <Row>
          <Col md={6} sm={12} xs={12}>
            {/* <p className="mb-0 border-bottom pb-1">Team Utilization Report</p> */}
            {this.state.userChartInfo && this.state.userChartInfo.length > 0 ? (<BarChartComponent type="User" info={this.state.userChartInfo} />) : ''}
          </Col>
          <Col md={6} sm={12} xs={12}>
            {/* <p className="mb-0 border-bottom pb-1">Last Month VS This Month</p> */}
            {this.state.monthUserChartInfo && this.state.monthUserChartInfo.length > 0 ? (<DoubleBarChartComponent info={this.state.monthUserChartInfo} />) : ''}
          </Col>
        </Row>
      </>
    );
  }
}

ResourceChart.propTypes = {
  dateInfo: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date)
  }).isRequired, // Validate dateInfo prop
  getUtilizationTeamReport: PropTypes.func.isRequired,
  getUtilizationMonthlyReport: PropTypes.func.isRequired,
  teamReport: PropTypes.array,
  monthlyReport: PropTypes.array,
  userReport: PropTypes.array,
  monthlyUserReport: PropTypes.array
};

function mapStateToProps(state) {
  return {
    teamReport: state.utilization.teamReport,
    monthlyReport: state.utilization.monthlyReport,
    userReport: state.utilization.userReport,
    monthlyUserReport: state.utilization.monthlyUserReport,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      getUtilizationTeamReport,
      getUtilizationMonthlyReport
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ResourceChart);
