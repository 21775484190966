import { USERS_RESET } from "../actions/index";

const INITIAL_STATE = {  
  userResetPassword: ''
};
export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {        
    case USERS_RESET:
      return {
        ...state,
        userResetPassword: action.payload,
      }
    default:
      return state;
  }
}
