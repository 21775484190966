import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from '@reduxjs/toolkit';
import ReduxThunk from "redux-thunk";
import allReducers from "./reducers";
import App from "./components/App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import "react-progress-2/main.css";
import Progress from "react-progress-2";

// Create the Redux store using configureStore from Redux Toolkit
const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(ReduxThunk), // Pass middleware as a callback function
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Progress.Component />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("MyContainer")
);
