import { FETCH_RESOURCE_MONTHLY_REPORT, FETCH_RESOURCE_TEAM_REPORT, FETCH_TEAMS } from "../actions/index";

const INITIAL_STATE = {
  teamResourceReport: {},
  monthlyResourceReport: {},
  teams: []
};
export default function resourceUtilizationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {

    case FETCH_RESOURCE_TEAM_REPORT:
      return {
        ...state,
        teamResourceReport: action.payload,
      }
    case FETCH_RESOURCE_MONTHLY_REPORT:
      return {
        ...state,
        monthlyResourceReport: action.payload,
      }
    case FETCH_TEAMS:
      return {
        ...state,
        teams: action.payload,
      }
    default:
      return state;
  }
}
